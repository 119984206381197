<template>
  <div>

    <div class="banner">
    </div>

    <div class="zone warehouse">
      <div class="area-title">
        <p class="title">业务范围</p>
      </div>
      <div class="warehouse-info">
        <p>至灿集团为构建标准化、规模化、品牌化的综合供应链体系，打造多元化服务，
          业务涵盖仓储物流、贸易、生产研发、电商运营、信息技术等领域，在全国设有多个生产基地，服务多家知名餐饮企业。</p>

      </div>
      <div class="warehouse-icons">
        <div class="warehouse-icons--item">
          <div class="warehouse-icons--item_icon">
            <img src="../../../assets/images/strength-business/v2/icon1.png">
          </div>
          <p class="warehouse-icons--item_desc">
            综合供应链体系
          </p>
        </div>
        <div class="warehouse-icons--item">
          <div class="warehouse-icons--item_icon">
            <img src="../../../assets/images/strength-business/v2/icon2.png">
          </div>
          <p class="warehouse-icons--item_desc">
            全国布局生产基地
          </p>
        </div>
      </div>
    </div>

    <div class="zone company">
      <div class="area-title">
        <p class="title">旗下公司</p>
      </div>
      <div class="company-content">
        <el-carousel
          class="company-carousel"
          ref="companyCarousel"
          height="47.6vw"
          indicator-position="none"
          :autoplay="true"
          arrow="always"
        >
          <el-carousel-item
            class="company-carousel-item"
            v-for="(item, index) in companies"
            :key="index"
          >
            <div
              class="company-carousel-item--bg"
              :style="{'background-image': 'url('+item.bgImgSrc+')'}"
            >
            </div>
            <div class="warehouse-icons warehouse-icons--company">
              <div class="company-info">
                <span class="company-info--name">{{item.name}}</span>
                {{item.info}}
              </div>
              <div class="company-icons">
                <div
                  class="warehouse-icons--item"
                  v-for="(icon, idx) in item.icons"
                  :key="idx"
                >
                  <div class="warehouse-icons--item_icon">
                    <img :src="icon.imgSrc">
                  </div>
                  <p class="warehouse-icons--item_desc">
                    {{icon.desc}}
                  </p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Home",
  components: {

  },
  data() {
    return {
      companies: [
        {
          bgImgSrc: require('@/assets/images/strength-business/ys/bg1.png'),
          name: '成都至远仓储服务有限公司:',
          info: '是成都至灿集团有限公司于2020年在成都成立的集仓储、物流、配送于一体的服务平台，依托遍布全国的仓网体系，深度整合多种类型的配送资源，为企业提供B2C+B2B的仓配一体化物流服务及商流服务。',
          icons: [
            {
              imgSrc: require('@/assets/images/strength-business/icon1.png'),
              desc: '集仓储'
            },
            {
              imgSrc: require('@/assets/images/strength-business/icon5.png'),
              desc: '专业团队'
            },
            {
              imgSrc: require('@/assets/images/strength-business/icon2.png'),
              desc: '物流配送'
            }
          ]
        },
        {
          bgImgSrc: require('@/assets/images/strength-business/v1/bg2.png'),
          name: '成都小黄橙科技有限公司:',
          info: '是至灿集团旗下专注线上运营业务的子公司。主要业务是针对本地生活、新媒体、电商平台以及消费者，提供营销策划，运营推广，渠道联动，品牌维护等一系列服务。到店板块通过大流量互联网平台进行推广，结合新美大运营服务为门店和品牌引流增收；到家板块通过外卖平台运营服务带来多渠道创收。',
          icons: [
            {
              imgSrc: require('@/assets/images/strength-business/icon4.png'),
              desc: '解决拓客，留客'
            },
            {
              imgSrc: require('@/assets/images/strength-business/icon5.png'),
              desc: '专业营销团队'
            },
            {
              imgSrc: require('@/assets/images/strength-business/icon6.png'),
              desc: '新媒体数字化'
            }
          ]
        },
        {
          bgImgSrc: require('@/assets/images/strength-business/v1/bg1.png'),
          name: '广元至和食品有限公司:',
          info: '是成都至灿集团有限公司于2020年10月在广元成立的火锅食材食品加工项目子公司，该项目属广元市利州区人民政府招商引资项目，坐落于广元市利州区宝轮工业园区。项目占地面积20000平，规划10个生产车间，以火锅食材生产/加工为主，专注于酱卤肉、速冻调理肉制品、火锅底料、调味料及方便食品的研发、生产及加工。',
          icons: [
            {
              imgSrc: require('@/assets/images/strength-business/v1/icon7.png'),
              desc: 'ISO食品安全体系'
            },
            {
              imgSrc: require('@/assets/images/strength-business/v1/icon8.png'),
              desc: '专业监管团队'
            },
            {
              imgSrc: require('@/assets/images/strength-business/v1/icon9.png'),
              desc: '严格食品检测'
            }
          ]
        },
        {
          bgImgSrc: require('@/assets/images/strength-business/v1/bg1.png'),
          name: '成都致臻至兴贸易有限公司:',
          info: '隶属于至灿集团，业务涉及食品、食材、农副产品、酒水、日用品等餐饮全品类销售，已形成2000+品类的强大产品阵容。销售网络布局全，以客户需求为出发点，链接供应链与全国各类餐饮门店，与百余家知名餐饮企业建立合作关系，将提高客户竞争优势，把好产品、好供应、好服务带进千万门店。 ',
          icons: [
            {
              imgSrc: require('@/assets/images/strength-business/v2/icon10.png'),
              desc: '好产品'
            },
            {
              imgSrc: require('@/assets/images/strength-business/v2/icon11.png'),
              desc: '好供应'
            },
            {
              imgSrc: require('@/assets/images/strength-business/v2/icon12.png'),
              desc: '好服务'
            }
          ]
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.banner {
  height: 42vw;
  background-image: url('../../../assets/images/strength-business/v2/banner.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.warehouse {
  height: 24vw;
  box-sizing: border-box;
  .warehouse-info {
    color: #333333;
    font-size: 0.83vw;
    line-height: 1.56vw;
    width: 46.25vw;
    margin: 0 auto;
    margin-top: 3.5vw;
  }
  .warehouse-icons {
    display: flex;
    justify-content: space-between;
    width: 26vw;
    margin: 0 auto;
    margin-top: 3.5vw;
  }
}

.warehouse-icons--item {
  width: 13rem;
  text-align: center;
  .warehouse-icons--item_icon {
    width: 4.6vw;
    height: 4.6vw;
    margin-bottom: 1.6vw;
    background-color: #e53a2e;
    img {
      width: 4.6vw;
      height: auto;
      margin-left: 2.3vw;
      margin-top: 50%;
      transform: translateY(-50%);
    }
  }
  .warehouse-icons--item_desc {
    color: #333333;
    font-size: 0.83vw;
  }
}

.company ::v-deep .el-carousel__arrow {
  color: #e61d0e;
  border: 1px dotted #e61d0e;
  width: 4.4rem;
  height: 4.4rem;
  line-height: 4.4rem;
  font-size: 3.2rem;
  top: 15vw;
  background-color: transparent;
}
.company ::v-deep .el-icon-arrow-right {
  font-weight: bold;
}
.company ::v-deep .el-icon-arrow-left {
  font-weight: bold;
}
.company {
  background: white;
  .company-content {
    margin-top: 4.6vw;
    .company-carousel-item {
      padding: 1.5vw 0 0;
      box-sizing: border-box;
      .company-carousel-item--bg {
        height: 26vw;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .company-icons {
        display: flex;
        justify-content: space-between;
        width: 68rem;
        margin: 0 auto;
        margin-top: 3.5vw;
      }
      .company-info {
        width: 52.3vw;
        margin: 0 auto;
        margin-top: 2.55vw;
        color: #333333;
        font-size: 0.83vw;
        line-height: 1.56vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        .company-info--name {
          font-size: 1.15vw;
          font-weight: bold;
        }
      }
    }
  }
}
.area-title {
  text-align: center;
  .title {
    position: relative;
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
    &::before {
      position: absolute;
      top: 5.4rem;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 10rem;
      height: 0.4rem;
      background-color: #e61d0e;
      border-radius: 0.2rem;
    }
  }
}
.zone {
  padding: 4.9rem 0 0;
}
</style>
